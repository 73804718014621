import { getXboxInfoWebview1 } from './getXboxInfoWebview1'
import { isWebview2 } from './isWebview2'
import { notifyXbox } from './notifyXbox'
import { WindowWithChromeWebviewPost, XboxAppStaticInfo } from './types'

const timeoutInMs = 10000

export const getXboxInfo = async (force = false): Promise<XboxAppStaticInfo> => {
  if (!isWebview2()) {
    return {
      ...getDefaultInfo(),
      ...getXboxInfoWebview1(),
    }
  }
  if (force) {
    clearInfo()
  }
  const info = getXboxAppStaticInfo()
  if (info) {
    return info
  }
  notifyXbox({ Message: 'UpdateAppInfo', Args: {} })
  const next = new Promise<XboxAppStaticInfo>((resolve) => {
    const startTime = new Date().getTime()
    const id = setInterval(() => {
      const info = getXboxAppStaticInfo()
      if (info) {
        clearInterval(id)
        resolve(info)
      } else if (new Date().getTime() - startTime > timeoutInMs) {
        clearInterval(id)
        resolve(getDefaultInfo())
      }
    }, 250)
  })
  return next
}

// HELPERS

function getXboxAppStaticInfo(): XboxAppStaticInfo | null {
  return typeof window === 'undefined' ? null : (window as WindowWithChromeWebviewPost).XboxAppStaticInfo ?? null
}

function clearInfo() {
  delete (window as WindowWithChromeWebviewPost).XboxAppStaticInfo
}

function getDefaultInfo(): XboxAppStaticInfo {
  return {
    appVersion: 'unknown',
    deviceFamily: 'Windows.Xbox', // this will ALWAYS be Windows.Xbox
    deviceModel: 'Xbox One',
    advertisingId: null,
    isLAT: true,
  }
}

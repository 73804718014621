import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { ConfigServiceContext, useContextUnconditionally } from '../../../services/config/ConfigService'
import { UserServiceContext } from '../../../services/user/UserService'

const TosRedirect = ({ tosPath = '/tos' }) => {
  const router = useRouter()
  const { currentConfig } = useContextUnconditionally(ConfigServiceContext)
  const userService = useContext(UserServiceContext)

  const latestTosVersion = currentConfig.API.requirements?.tos_version
  const userTosVersion = userService.currentUser?.profile?.internal?.tos_version

  useEffect(() => {
    const ignoredPaths = [tosPath, '/activate', 'activateTV', '/terms-of-use', '/privacy-policy', '/hardwall']
    const isLooping = router.query?.redirected === 'true'
    const shouldNotRedirect = isLooping || ignoredPaths.includes(router.pathname) || !userService.isLoggedIn
    if (shouldNotRedirect) return

    if (latestTosVersion && latestTosVersion !== userTosVersion) {
      const targetUrl = new URL(tosPath, window.location.origin)
      targetUrl.searchParams.set('redirected', 'true')
      router.push(targetUrl.pathname + targetUrl.search)
    }
  }, [latestTosVersion, router, tosPath, userService.isLoggedIn, userTosVersion])

  return <></>
}

export default TosRedirect
